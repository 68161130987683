.colour-display {
    width: 60px;
    height: 30px;
    border-style: solid;
    border-width: 4px;
    border-color: gray;
    position: relative;
}

.color-selection-card {
    width: 350px;
    position: absolute;
    display: flex;
    top: 30px;
    left: -4px;
    z-index: 2;
}

.draw-icon {
    font-size: 30px;
}