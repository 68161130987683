.App {
  text-align: center;
  height: 100%;
}

.top-container {
  margin: 2rem;
}

.clickable {
  cursor:pointer;
}

.dot-app {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.dot-app.red {
  background-color: #ff0000;
}

.dot-app.green {
  background-color: #73ff00;
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
}

.carousel-indicators button {
    background-color: #0d6efd !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230d6efd'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230d6efd'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.objectfit-conatin{
    object-fit: contain;
}
